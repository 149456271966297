import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Col, Grid, Button, Layout } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import Header from '../Header';
import TabsPlayer from '../Tabs';
import { loadMatomo } from '../../utils/functions/matomo';
import {
  getBrandSetting,
  getContent,
  handleSideBar
} from '../../utils/functions/contentParams';
import { GetVideoIdFromRecoil } from '../../recoil/playlist';
import { getCurrentItemFromPlaylist } from '../../utils/functions/player';

const VideoPlayer = dynamic(() => import('../Player/player2.js'), {
  ssr: false
});
const Description = dynamic(() => import('../Description'), { ssr: false });

const { Content, Sider } = Layout;
const { useBreakpoint } = Grid;

const Main = function ({ live, media, playlist, isEmbed, accentColor }) {
  const { content, contentType } = getContent(media, live, playlist);
  const brandSetting = getBrandSetting(content, isEmbed);
  const { shouldShowSideBar, sidebarHasContent } = handleSideBar(
    content,
    contentType,
    brandSetting
  );
  const [disableAnalytic, setDisableAnalytic] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [contentHeight, setContentHeight] = useState('initial');
  const [videoTitle, setVideoTitle] = useState();
  const [videoLanguage, setVideoLanguage] = useState();
  const screens = useBreakpoint();
  const router = useRouter();

  const currentPlaylistContentId = GetVideoIdFromRecoil();

  useEffect(() => {
    if (shouldShowSideBar && sidebarHasContent) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  }, [shouldShowSideBar]);

  useEffect(() => {
    loadMatomo();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }, [collapsed]);

  useEffect(() => {
    let height = 'initial';
    if (isEmbed && !screens.xs && screens.md) height = '100vh';
    else if (screens.md) height = `calc(100vh - 90px)`;
    setContentHeight(height);
  }, [isEmbed, screens]);

  useEffect(() => {
    if (live?.status === 'Ended' && live?.hasReplay && live?.replay?.id) {
      setShouldRedirect(true);
      // TODO prefetech the next router page with <Link>
      setTimeout(() => {
        if (isEmbed) {
          window.location = `/embed/media/${live.replay.id}`;
        } else {
          window.location = `/media/${live.replay.id}`;
        }
      }, 5000);
    }
  }, [live, isEmbed, router]);

  // set the video title because there is some logic to do with playlist
  useEffect(() => {
    if (!content || !contentType) return;
    if (contentType !== 'playlist') {
      setVideoTitle(content.pageSetting?.videoTitle);
    } else {
      const item = getCurrentItemFromPlaylist(
        content,
        currentPlaylistContentId
      );
      console.log('item', item);
      setVideoTitle(item?.pageSetting?.videoTitle);
      setVideoLanguage(item?.language);
    }
  }, [content, contentType, currentPlaylistContentId]);

  useEffect(() => {
    if (router.query.disableAnalytic) {
      setDisableAnalytic(true);
    } else {
      content?.disableAnalytic && setDisableAnalytic(content?.disableAnalytic);
    }
  }, [content]);

  // MEDIAS & PLAYLISTS & LIVE
  return (
    <Layout style={{ minWidth: '100%' }} data-cy="layout">
      <Layout
        style={{
          minWidth: '100%',
          minHeight:
            screens.xs && screens.md && !isEmbed
              ? 'calc(100vh - 65px)'
              : 'initial',
          height: contentHeight,
          position: 'relative'
        }}
      >
        <Content>
          {/* {sidebarHasContent && screens.md && (
            <Button
              type="link"
              style={{
                fontSize: ' 1.6em',
                top: 0,
                right: !collapsed ? 'calc(33% - 50px)' : 0,
                position: 'absolute',
                zIndex: 1000,
                width: 50,
                height: 50,
                transition: 'all .3s ease-in-out'
              }}
              data-cy="sidebar-trigger"
              onClick={() => setCollapsed(!collapsed)}
            >
              {collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
            </Button>
          )} */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              background: 'rgb(255,255,255)',
              background:
                'linear-gradient(180deg, rgba(255,255,255,1) 50%, #e7001c 50%)'
            }}
          >
            <Header
              pageLogo={content?.pageSetting?.pageLogo}
              pageTitle={content?.pageSetting?.pageTitle}
            />
            <div style={{ padding: '120px 16px 0 16px' }}>
              <VideoPlayer
                media={media}
                live={live}
                playlist={playlist}
                isEmbed={isEmbed}
                collapsed={collapsed}
                accentColor={accentColor}
                shouldRedirect={shouldRedirect}
                disableAnalytic={disableAnalytic}
              />
              <p
                style={{
                  color: 'white',
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  padding: 32
                }}
              >
                Cliquez{' '}
                <a href="https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fmeetup-join%2F19%3Ameeting_N2M5MWJiMDItMjcwZC00YjUxLTlkMjYtYWVlMzQyZGVlMjA4%40thread.v2%2F0%3Fcontext%3D%257b%2522Tid%2522%253a%252242ae9275-22b7-4767-b3d9-6de9f734dcd4%2522%252c%2522Oid%2522%253a%252232ac42d3-c276-4d8c-a7df-7673b620bac2%2522%257d%26anon%3Dtrue&type=meetup-join&deeplinkId=fb3d53e4-6315-40c3-bb39-c6b1042f438f&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true">
                  ici
                </a>{' '}
                à la fin de la plenière pour réjoindre le jeu intéractif et ...
                <br />
                Sortez vos smartphones !!
              </p>
            </div>
            {/* {!isEmbed && (
              <Description
                title={videoTitle}
                language={videoLanguage}
                description={content.pageSetting?.pageText}
                shouldShowSummary={content.pageSetting?.shouldShowSummary}
                shouldShowShareButton={
                  content.pageSetting?.shouldShowShareButton
                }
                collapsed
                accentColor={accentColor}
              />
            )} */}
          </div>

          {/* {!screens.md && sidebarHasContent && (
            <Col span={24}>
              <TabsPlayer
                isEmbed={isEmbed}
                media={media}
                live={live}
                playlist={playlist}
                accentColor={accentColor}
                theme={content?.pageSetting?.pageTheme}
                title={videoTitle}
              />
            </Col>
          )} */}
        </Content>
        {/* {sidebarHasContent && screens.md && (
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            collapsedWidth={0}
            width="33%"
            theme={content?.pageSetting?.pageTheme}
          >
            <TabsPlayer
              isEmbed={isEmbed}
              media={media}
              live={live}
              playlist={playlist}
              accentColor={accentColor}
              theme={content?.pageSetting?.pageTheme}
              title={videoTitle}
            />
          </Sider>
        )} */}
      </Layout>
    </Layout>
  );
};

export default Main;
