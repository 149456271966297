import React from 'react';

const DarkTheme = () => (
  <style global jsx>{`
    body {
      color: rgba(255, 255, 255, 0.85);
      background: #141414;
    }
    p,
    .ant-collapse-arrow {
      color: rgba(255, 255, 255, 0.85);
    }
    .ant-layout {
      background: #141414;
    }
    .ant-layout-header {
      color: rgba(255, 255, 255, 0.85);
      background: #141414 !important;
    }
    .ant-select {
      color: rgba(255, 255, 255, 0.85);
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: transparent;
      border: 1px solid #434343;
    }
    .ant-select-dropdown {
      color: rgba(255, 255, 255, 0.85);
      background-color: #141414;
      box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
        0 9px 28px 8px rgb(0 0 0 / 5%);
    }
    .ant-select-single.ant-select-open .ant-select-selection-item {
      color: rgba(255, 255, 255, 0.3);
    }
    .ant-select-arrow {
      color: rgba(255, 255, 255, 0.3);
    }
    .ant-select-item {
      color: rgba(255, 255, 255, 0.85);
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      color: rgba(255, 255, 255, 0.85);
      background-color: #1f1f1f;
    }
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color: #1f1f1f;
    }
    .ant-layout-sider {
      background: #252525;
    }
    .ant-tabs-top > .ant-tabs-nav::before {
      border-bottom: 1px solid #303030;
    }
    .ant-tabs,
    .ant-modal,
    .ant-empty-normal,
    h1,
    h2,
    h3 {
      color: rgba(255, 255, 255, 0.85);
    }
    .ant-collapse-ghost {
      background-color: transparent !important;
    }
    .ant-input {
      color: rgba(255, 255, 255, 0.85);
      background-color: transparent;
      border: 1px solid #434343;
    }
    .ant-form-item-has-error .ant-input,
    .ant-form-item-has-error .ant-input-affix-wrapper,
    .ant-form-item-has-error .ant-input:hover,
    .ant-form-item-has-error .ant-input-affix-wrapper:hover {
      background-color: transparent;
    }
    .ant-modal-content {
      background-color: #141414;
      box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
        0 9px 28px 8px rgb(0 0 0 / 5%);
    }
    .ant-modal-header {
      color: rgba(255, 255, 255, 0.85);
      background: #141414;
      border-bottom: 1px solid #303030;
    }
    .ant-modal-title {
      color: rgba(255, 255, 255, 0.85);
    }
    .ant-modal-close {
      color: rgba(255, 255, 255, 0.45);
      background: transparent;
    }
    .ant-modal-close:focus,
    .ant-modal-close:hover {
      color: rgba(255, 255, 255, 0.75);
    }
    .ant-input-affix-wrapper-disabled {
      color: rgba(255, 255, 255, 0.3);
      background-color: rgba(255, 255, 255, 0.08);
    }
    .ant-input[disabled] {
      color: rgba(255, 255, 255, 0.3);
    }
    .ant-input-textarea-show-count::after {
      color: rgba(255, 255, 255, 0.3);
    }

    .ant-card {
      color: rgba(255, 255, 255, 0.85);
      background: #141414;
      border-radius: 2px;
      border: 1px solid #303030;
    }
    .ant-comment-content-author-name,
    .ant-comment-actions > li,
    .ant-comment-actions > li > span {
      color: rgba(255, 255, 255, 0.45) !important;
    }
    .ant-comment-content-author-time {
      color: rgba(255, 255, 255, 0.3);
    }
    .formDark {
      background: #252525;
    }
  `}</style>
);

export default DarkTheme;
