import React from 'react';
import dynamic from 'next/dynamic';
import { Tabs } from 'antd';
import { useTranslation } from 'next-i18next';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import {
  getBrandSetting,
  getContent,
  handleSideBar
} from '../../utils/functions/contentParams';
import { GetVideoIdFromRecoil } from '../../recoil/playlist';

const Discussion = dynamic(() => import('../Discussion'));
const Chapters = dynamic(() => import('../Chapters'));
const Transcript = dynamic(() => import('../Transcript'));
const Files = dynamic(() => import('../Files'));
const Playlist = dynamic(() => import('../Playlist'));

const { TabPane } = Tabs;

const TabsPlayer = function ({ isEmbed, media, live, playlist, title }) {
  const { t } = useTranslation('common');
  const screens = useBreakpoint();
  const { content, contentType, isPlaylist } = getContent(
    media,
    live,
    playlist
  );
  const brandSetting = getBrandSetting(content, isEmbed);
  const { accentColor, theme } = brandSetting;
  const discussionId = contentType !== 'playlist' && content?.discussion?.id;
  const currentPlaylistContentId = GetVideoIdFromRecoil();

  // fix the is embed missing (in discussion scope, not live/media)
  // const shouldShowDiscussion = (media || live)?.shouldShowDiscussion && (media || live)?.isEmbed
  const {
    files,
    shouldShowTranscript,
    transcripts,
    shouldShowChapter,
    chapters,
    shouldShowDiscussion
  } = handleSideBar(
    content,
    contentType,
    brandSetting,
    currentPlaylistContentId
  );

  return (
    <div style={{ height: '100%' }}>
      <Tabs defaultActiveKey="1">
        {isPlaylist && (
          <TabPane
            tab={<span data-cy="tab-playlist">{t('playlist')}</span>}
            key="0"
          >
            <Playlist
              playlist={content}
              color={accentColor}
              theme={theme}
              isEmbed={isEmbed}
            />
          </TabPane>
        )}

        {shouldShowDiscussion && discussionId && (
          <TabPane
            tab={
              <span data-cy="tab-discussion">
                {/* <CommentOutlined /> */}
                {t('discussion')}
              </span>
            }
            key="1"
          >
            {/* {process.browser && ( */}
            <Discussion discussionId={discussionId} theme={theme} />
            {/* )} */}
          </TabPane>
        )}

        {shouldShowChapter && chapters.length && (
          <TabPane
            tab={
              <span data-cy="tab-chapter">
                {/* <BookOutlined /> */}
                {t('chapters')}
              </span>
            }
            key="2"
          >
            {contentType === 'playlist' && (
              <h3 className="tab-title">
                {t('chapters')} {title}
              </h3>
            )}
            <Chapters chapters={chapters} live />
          </TabPane>
        )}

        {shouldShowTranscript && transcripts.length && (
          <TabPane
            tab={
              <span data-cy="tab-transcript">
                {/* <TranslationOutlined /> */}
                {t('transcript')}
              </span>
            }
            key="3"
          >
            {contentType === 'playlist' && (
              <h3 className="tab-title">
                {t('transcript')} {title}
              </h3>
            )}
            <Transcript subtitles={transcripts} color={accentColor} />
          </TabPane>
        )}

        {files.length && (
          <TabPane
            tab={
              <span data-cy="tab-file">
                {/* <FileOutlined /> */}
                {t('files')}
              </span>
            }
            key="4"
          >
            {contentType === 'playlist' && (
              <h3 className="tab-title">
                {t('files')} {title}
              </h3>
            )}
            <Files files={files} />
          </TabPane>
        )}
      </Tabs>

      <style global jsx>{`
        .ant-tabs-nav {
          padding: ${screens.md ? 'initial' : '0 0 0 24px'};
          padding-left: ${screens.md ? '48px' : 'initial'};
        }
        .ant-tabs-nav-wrap {
          width: 100%;
        }
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
        .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
        .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
        .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
        .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
          opacity: 0;
        }
        .ant-tabs-nav-list {
          width: 100%;
        }
        .ant-tabs-tab {
          display: flex;
          justify-content: center;
          flex-grow: 1;
          padding: ${screens.md ? '16px 8px;' : '16px 4px'};
          margin: 0 12px;
        }
        .ant-tabs-tab + .ant-tabs-tab {
          margin: 0 16px;
        }
        .ant-tabs-nav {
          margin: 0 !important;
        }

        .ant-tabs-tabpane {
          overflow-x: ${screens.md ? 'auto' : 'initial'};
          padding: 24px;
        }

        .ant-tabs,
        .ant-tabs-content {
          height: 100%;
          max-height: 100%;
        }

        .tab-title {
          font-size 16px;
          margin: 0 0 16px;
        }

        // fIX FOR SAFARI
        .ant-tabs-content-holder {
          display: flex;
        }

        // // .ant-tabs-content-holder {
        // //   overflow: ${!screens.md ? 'auto' : 'initial'};
        // //   max-height: ${!screens.md ? '40vh' : 'initial'};
        // // }
      `}</style>
    </div>
  );
};

export default TabsPlayer;
