import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import ErrorPage from 'next/error';
import { useQuery } from '@apollo/client';
import { useSetRecoilState } from 'recoil';
import Theme from '../../theme';
import { GET_UPDATED_LIVE } from '../../utils/subscription/live';
import Main from '../Main';
import Head from '../Head';
import LoaderSpin from '../LoaderSpin';
import Confidentiality from '../Confidentiality';
import { getContentParams } from '../../utils/functions/contentParams';
import useHeaders from '../../utils/hooks/useHeaders';
import { GET_UPDATED_LIVE_PLAYLIST } from '../../utils/subscription/playlist';
import { liveIdToUpdateState } from '../../recoil/playlist';

const Page = function ({
  contentFromServer,
  errorFromServer,
  isEmbed = false
}) {
  const [content, setContent] = useState(contentFromServer);
  const [error, setError] = useState();
  const router = useRouter();

  // https://nextjs.org/docs/routing/dynamic-routes#caveats
  // to note, while next is re-hydrating, query from router is empty
  const mediaId = '';
  const liveId = 'ckymtzv7a415972hvi23fnp3hz';
  const playlistId = '';
  const noContent = !mediaId && !liveId && !playlistId && !error;

  const { query, contentId, contentType } = getContentParams(
    mediaId,
    liveId,
    playlistId
  );

  // re-hydrate next with new data
  const {
    data,
    error: apolloError,
    subscribeToMore
  } = useQuery(query, {
    variables: {
      mediaId,
      liveId,
      playlistId
    },
    context: {
      headers: useHeaders()
    },
    skip: noContent
  });

  const setRecoilLiveIdToUpdateState = useSetRecoilState(liveIdToUpdateState);

  // LIVE SUBSCRIPTIONS
  useEffect(() => {
    if (!subscribeToMore || !liveId || noContent || router.isFallback) return;
    // the try catch is to avoid error with next.js fast refresh
    // https://github.com/apollographql/apollo-client/issues/6437
    try {
      subscribeToMore({
        document: GET_UPDATED_LIVE,
        variables: {
          id: liveId
        },
        updateQuery: (prev, { subscriptionData }) => {
          console.log('subscriptionData', subscriptionData);
          if (!subscriptionData) return prev;
          return {
            ...prev,
            live: { ...prev.live, ...subscriptionData.data.live }
          };
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [subscribeToMore]);

  // PLAYLIST SUBSCRIPTIONS
  useEffect(() => {
    if (!subscribeToMore || !playlistId || noContent || router.isFallback)
      return;
    // the try catch is to avoid error with next.js fast refresh
    // https://github.com/apollographql/apollo-client/issues/6437
    try {
      subscribeToMore({
        document: GET_UPDATED_LIVE_PLAYLIST,
        variables: {
          playlistId
        },
        updateQuery: (prev, { subscriptionData }) => {
          setRecoilLiveIdToUpdateState(subscriptionData.data.playlistLive.id);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [subscribeToMore]);

  useEffect(() => {
    const dataRecevied = data && data[contentType];
    if (!dataRecevied) return;
    setContent(dataRecevied);
    setError(null);
  }, [data, router]);

  useEffect(() => {
    if (!apolloError) return;
    setError(apolloError?.graphQLErrors[0].originalError.extensions);
  }, [apolloError]);

  // while the next is fetching the data
  // show the loader
  if (router.isFallback) {
    return <LoaderSpin />;
  }

  // if the content doesn't exist
  // and if the content is not protected (if the content is protected, no content is returned)
  if (noContent) {
    return <ErrorPage statusCode={404} />;
  }

  // if the content is not published
  if (
    (mediaId || playlistId) &&
    content &&
    !content?.isPublished &&
    !router.query.allowUnpublished
  ) {
    return <ErrorPage statusCode={404} />;
  }

  // If live status is Archived
  if (content?.status === 'Archived') {
    return <ErrorPage statusCode={404} />;
  }

  // hack to avoid the blinking privacy form when cookie or token is stored in cookie (client side)
  if (errorFromServer && !error && !content) {
    return <LoaderSpin />;
  }

  // if the content is protected
  if (error) {
    return (
      <>
        <Confidentiality
          errorData={error}
          contentId={contentId}
          contentType={contentType}
        />
        <Theme accentColor={error?.contentColor} />
      </>
    );
  }

  const accentColor = isEmbed
    ? content?.embedSetting?.accentColor
    : content?.pageSetting?.accentColor;
  const pageTheme = isEmbed
    ? content?.embedSetting?.pageTheme
    : content?.pageSetting?.pageTheme;

  return (
    <>
      <Head
        media={mediaId ? content : null}
        live={liveId ? content : null}
        playlist={playlistId ? content : null}
        locale={router.locale}
      />
      <Main
        media={mediaId ? content : null}
        live={liveId ? content : null}
        playlist={playlistId ? content : null}
        isEmbed={isEmbed}
        accentColor={accentColor}
      />
      <Theme accentColor={accentColor} theme={pageTheme} />
    </>
  );
};

export default Page;
